import React from 'react'
import './Footer.css'
import facebookIncon from '../../assets/img/facebookIncon.png'
import InstaIcon from '../../assets/img/InstaIcon.png'
import xIcon from '../../assets/img/xIcon.png'
import youtubeIcon from '../../assets/img/youtubeIcon.png'
import weebseatL from '../../assets/img/weebSeat.png'

export const Footer = () => {
  return (
    <div className="landing-footer-container">
      <div className="landing-footer-upper-box">
        <div className="landing-footer-upper-box-contact">
          <h4 className="landing-footer-upper-box-heading">Contact</h4>
          <div className="landing-footer-upper-box-para">
            <a className="general-link-decoration" href="https://weebseat.com/landing/about-us.html">About us</a>
          </div>

          <div className="landing-footer-upper-box-para">
            <p>Need Help? Mail Us</p>
            <p className="general-gradient-color">support@weebseat.com</p>
          </div>
        </div>

        <div className="landing-footer-upper-box-More">
          &nbsp;
        </div>

        <div className="landing-footer-upper-box-Weebseat">
          <div className="landing-footer-upper-box-Weebseat-about-box">
            <div
              className="landing-footer-upper-box-Weebseat-about-weebSeat-image-box"
            >
              <img
                src={weebseatL}
                alt="Weebseat"
              />
            </div>
            <p
              className="landing-footer-upper-box-para landing-footer-upper-box-Weebseat-description"
            >
              Our Proprietary Ai Analyzes Your Business, Detects Your Unique
              Content Needs And Acquisition Levers, And Finds The Perfect
              Compromise Between Creativity And Strategy.
            </p>
          </div>

          <div className="landing-footer-upper-box-Weebseat-social-container">
            <div className="landing-footer-upper-box-Weebseat-social-Inner-Box">
              <div className="landing-footer-upper-box-Weebseat-about-separator"></div>

              <div className="icons-parent">
                <a
                  href="https://www.youtube.com/@weebseat"
                  className="landing-footer-upper-box-Weebseat-social-icon"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src={youtubeIcon}
                    alt="Youtube"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61555849972020"
                  className="landing-footer-upper-box-Weebseat-social-icon"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src={facebookIncon}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://x.com/weebseat"
                  className="landing-footer-upper-box-Weebseat-social-icon"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    src={xIcon}
                    alt="X"
                  />
                </a>

                <a
                  href="https://www.instagram.com/weebseat/"
                  target="_blank" rel="noreferrer"
                  className="landing-footer-upper-box-Weebseat-social-icon"
                >
                  <img
                    src={InstaIcon}
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-footer-upper-box-get-in-touch">
          <h4 className="landing-footer-upper-box-heading">More</h4>
          <div
            className="landing-footer-upper-box-tabs landing-footer-upper-box-para"
          >
            <a className="general-link-decoration" href="https://weebseat.com/landing/terms-of-use.html">Terms of Use</a>
            <a className="general-link-decoration" href="https://weebseat.com//blog">Blog</a>
            <a className="general-link-decoration" href="https://weebseat.com/landing/privacy-policy.html">Privacy Policy</a>
          </div>
        </div>
      </div>

      <div className="landing-footer-upper-box-para landing-footer-lower-box">
        <p>Copyright © 2024 Weebseat. All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer
