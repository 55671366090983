import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {message, Modal, Button, ConfigProvider} from 'antd'
import logo from '../../assets/img/logo.png';
import cross from '../../assets/img/cross.png';
import calendar from '../../assets/img/calendar.png';
import headphone from '../../assets/img/headphone.png';
import domain from '../../assets/img/domain.png';
import './Sidedash.css';
import env from 'react-dotenv';

const Sidedash = ({ passLoginStatus, isActive, handleToggle }) => {
  const [correction, setCorrection] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const getActiveClass = (path) => location.pathname === path ? 'active' : '';

  /**
   * useEffect hook that tracks the changes in the URL path.
   * Checks if the current pathname is '/dashboard' and updates the `correction` state.
   * The `correction` state is set to true if the current path is '/dashboard',
   * otherwise it is set to false to indicate that the user has navigated away from the dashboard.
   *
   * Dependencies:
   * - location: The current location object from the `useLocation` hook.
   *
   * @param {Object} location - The current location object.
   * @param {string} location.pathname - The path of the current URL.
   * 
   * Side-effect:
   * - Updates the `correction` state based on the current path.
   */
  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setCorrection(true);
    } else {
      setCorrection(false); // Assure que `correction` est mis à jour si on quitte le dashboard
    }
  }, [location]);
 

  /**
   * Navigates to the specified path.
   * 
   * This function uses the `navigate` method from `react-router-dom` to change the current route 
   * to the provided path.
   *
   * @param {string} path - The target path to navigate to.
   */
  const navigateTo = (path) => {
    navigate(path);
  };

    ///////////////////////////////CLICK SUR LOGOUT /////////////////////////////
    /**
     * Handles the logout process.
     * 
     * This asynchronous function performs the following steps:
     * 1. Makes a GET request to the backend logout endpoint (`/logout`) to terminate the session.
     * 2. If the response indicates a successful logout, it:
     *    - Removes 'user', 'generation', and 'auth' items from local storage.
     *    - Calls the `passLoginStatus` function with `false` to update the login status.
     *    - Navigates the user to the '/login' route.
     * 3. If the logout attempt fails, displays an error message using Ant Design's `message` component.
     * 
     * Dependencies:
     * - env.REACT_APP_BACK_URL: The base URL of the backend.
     * - passLoginStatus: Function prop to update the login status in the parent component.
     * - navigate: Function from `react-router-dom` for navigation.
     * 
     * Side-effects:
     * - Makes a network request to the logout endpoint.
     * - Updates local storage and navigation based on the logout response.
     */
    const handleLogout = async () => {
      //deconnection back
      const logout_resp = await fetch(`${env.REACT_APP_BACK_URL}/logout`,
      {
        method: 'GET',
        credentials: 'include',
      })
      .then(res=>res.json())
      .then(data => data)
      if(logout_resp.success){
        localStorage.removeItem('user');
        localStorage.removeItem('generation');
        localStorage.removeItem('auth');
        passLoginStatus(false)
        navigate('/login');
      }else{
        message.error('Impossible to logout ! please try later')
      }
    }

    // Fonction pour gérer l'affichage de la modale
  const handleSubscription = () => {
    setIsModalVisible(true); // Affiche la modale
  };

  // Fonction pour fermer la modale
  const handleOk = () => {
    setIsModalVisible(false); // Cache la modale
  };

  return (
    <ConfigProvider
      theme={{
          components: {
              Button: {
                  colorPrimary: `#E16400`,
                  colorPrimaryHover: `#E16400`,
                  colorPrimaryActive: `#E16400`,
                  lineWidth: 1,
                  defaultBg: `#EDF2F6`,
                  controlHeight: 60,
                  borderRadius: 9.38,
                  defaultBorderColor: `#E0E4EC`,
                  fontSize: 19.26,
                  fontFamily: `gotham pro`,
                  fontWeight: 500,
              },
              Mentions: {
                  colorPrimary: `#E16400`,
                  colorPrimaryHover: `#E16400`,
                  colorPrimaryActive: `#E16400`,
                  lineWidth: 1,
                  fontSize: '20px',
                  padding:'5px',
                  autoSize:true,
                  
              },
              Switch: {
                  colorPrimary: `#E16400`,
                  colorPrimaryHover: `#E16400`,
                  colorPrimaryActive: `#E16400`,
                  lineWidth: 1,
              }
          },
      }}
    >
      <div id="sidebar" className={`sidebar ${isActive ? 'active' : ''}`}>
        <img id="menu-close" className="cross" src={cross} alt="cross" onClick={handleToggle} />
        <img className="logo" src={logo} alt="logo" onClick={() => navigateTo('/generator')}/>
        <div className="menu-list">
          {/*<div className={`menu ${getActiveClass('/subscription')}`} onClick={() => navigateTo('/subscription')}>
            <img src={calendar} alt="calendar" />
            <p className={ correction ? 'correction': ''}>Monthly/Annual Subscription</p>
          </div>*/}
          <div className={`menu ${getActiveClass('/subscription')}`} onClick={handleSubscription}>
            <img src={calendar} alt="calendar" />
            <p className={ correction ? 'correction': ''}>Monthly/Annual Subscription</p>
          </div>
          <div className={`menu ${getActiveClass('/support')}`} onClick={() => navigateTo('/support')}>
            <img src={headphone} alt="headphone" />
            <p className={`technical ${correction ? 'correction': ''}` }>Technical Support and Consulting</p>
          </div>
          <div className={`menu ${getActiveClass('/domain')}`} onClick={() => navigateTo('/domain')}>
            <img src={domain} alt="domain" />
            <p className={`technical ${correction ? 'correction': ''}`}>Connect my Domain</p>
          </div>
        </div>
        <div className="footer-menu-list">
          <div className={`menu ${getActiveClass('/dashboard')} `}  onClick={() => navigateTo('/dashboard')}>
            <p className={`${correction ? 'correction': ''}`}>Settings</p>
          </div>
          <div className="menu">
            <p className={`logout ${correction ? 'correction': ''}`} onClick={handleLogout}>Logout</p>
          </div>
        </div>
              {/* Ajout de la modale */}
              <Modal
              title="Comming soon"
              open={isModalVisible}
              footer={[
                  <Button key="ok" type="primary" onClick={handleOk}>
                      OK
                  </Button>,
              ]}
              >
              <p className='modal-txt'>This feature will be available in the coming weeks. Stay tuned!</p>
          </Modal>
      </div>
    </ConfigProvider>
  );
};

export default Sidedash;
