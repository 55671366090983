import React from 'react';
import { Card, Badge } from 'antd';
import './SubscriptionStatus.css';

/**
 * SubscriptionStatus component displays the status of a subscription with appropriate styles and messages.
 * 
 * Props:
 * @param {boolean} isSubValid - Indicates if the subscription is valid.
 * @param {string} subValidDate - Represents the date until the subscription is valid.
 * @param {number} countdown - Number of days remaining in the trial period.
 * @param {string} invalidWhy - Reason why the subscription is not valid.
 * @param {string} nextBillingDate - The date of the next billing cycle.
 * 
 * The component uses these props to conditionally render:
 * - Status badge with appropriate color and text.
 * - Trial period information if applicable.
 * - Next billing date for valid subscriptions.
 * - Information regarding invalid subscription reasons.
 * - A button to add payment method if the subscription is inactive or in trial.
 * 
 * The colors for subscription status are:
 * - Green (#52c41a) for valid subscriptions.
 * - Red (#f5222d) for inactive or non-valid subscriptions.
 * - Orange (#fa8c16) for trial periods or approval pending status.
 * - Grey (#d9d9d9) for unknown statuses.
 */
const SubscriptionStatus = ({ isSubValid, subValidDate, countdown, invalidWhy, nextBillingDate, handleCreateSub }) => {
    console.log('subvalid in subscription status', isSubValid);
    
    // Utilisation d'un ternaire pour initialiser les variables `status` et `statusColor` en fonction de `isSubValid`
    let [status, statusColor] = isSubValid
        ? ['Valid', '#52c41a'] // Green pour un abonnement valide
        : ['Inactive', '#f5222d']; // Red par défaut pour un abonnement non valide
    
    let showPaymentButton = false;

    // Logique pour les statuts non valides et la période d'essai
    if (countdown > 0) {
        status = 'Trial';
        statusColor = '#fa8c16'; // Orange pour la période d'essai
        showPaymentButton = true;
    } else if (!isSubValid) {
        switch (invalidWhy) {
            case 'cancelled':
            case 'expired':
            case 'suspended':
            case 'inactive':
                status = 'Inactive';
                statusColor = '#f5222d'; // Rouge pour l'abonnement inactif
                showPaymentButton = true;
                break;
            case 'no subscription':
                status = 'Inactive';
                statusColor = '#f5222d'; // Rouge également
                showPaymentButton = true;
                break;
            case 'pending':
                status = 'Pending';
                statusColor = '#fa8c16'; // Orange pour le statut "pending"
                showPaymentButton = false;
                break;
            default:
                status = 'Unknown';
                statusColor = '#d9d9d9'; // Gris pour un cas inattendu
                showPaymentButton = true;
                break;
        }
        
    }

    return (
        <Card
            className='card-sub-status'
            bordered={false}
            style={{
                maxWidth: 350,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '20px 20px',
                borderRadius: '10px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                fontFamily: 'Gotham Pro',
            }}
        >
            <div>
                <span className='sub-status1' >Subscription Status</span>
                <Badge
                    count={status}
                    style={{
                        backgroundColor: statusColor,
                        marginLeft: '10px',
                        padding: '0 10px',
                        borderRadius: '12px',
                        color: 'white',
                        fontSize: '14px',
                        lineHeight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            </div>
            <div style={{ fontSize: '14px', color: statusColor }}>
                {isSubValid || countdown > 0 ? (
                    <div className='abo abo-valid'>
                        {/* Affiche le compte à rebours si en période d'essai */}
                        {countdown > 0 ? `${countdown} Days Remaining` : `Until ${subValidDate}`}
                        {/* Ajoute la date de la prochaine facturation si l'abonnement est valide ou en période d'essai */}
                        {isSubValid && nextBillingDate && (
                            <div style={{ marginTop: '5px' }}>
                                Next Billing Date: {nextBillingDate}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='abo abo-invalid' style={{ textAlign: 'left' }}>
                        {invalidWhy === 'no subscription' ? 'No Subscription' : `Subscription ${invalidWhy}`}
                        {showPaymentButton && (
                            /*<a href="./subscription" style={{ display: 'block', marginTop: '5px' }}>
                                <button style={{ backgroundColor: '#1890ff', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer', marginTop: '1em', marginBottom: '1em' }}>
                                    Add Payment Method
                                </button>
                            </a>*/
                        
                            
                            <button onClick={handleCreateSub} style={{ backgroundColor: '#1890ff', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer', marginTop: '1em', marginBottom: '1em' }}>
                                Add Payment Method
                            </button>
                            
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};

export default SubscriptionStatus;
