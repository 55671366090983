import React from 'react';
import { useNavigate } from 'react-router-dom';
import env from 'react-dotenv';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Form, Input, Button, Space, Checkbox, Divider, ConfigProvider } from 'antd';
import { FacebookFilled, GoogleCircleFilled, LockOutlined, UserOutlined , AppleOutlined} from '@ant-design/icons';
import Alertbox from '../../components/Alertbox/Alertbox';
import 'antd/dist/reset.css';
import './Login.css';
import { Footer } from '../../components/Footer/Footer';
//import AppleLogin from 'react-apple-login';
import AppleSignin from 'react-apple-signin-auth';


const Login = ({ passLoginStatus, onLogged }) => {
  const navigate = useNavigate();

  // ETAT INITIAUX
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false); // État pour la case "Remember Me"
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);

/**
 * This code handles the "Remember Me" functionality and the login process.
 *
 * The useEffect hook is used to retrieve the saved email and password from local storage
 * when the component mounts. If these values are present, they are set as the initial
 * values for the email and password fields, and the "Remember Me" checkbox is checked.
 *
 * The loginUser function sends a POST request to the backend with the user's credentials.
 * If the request is successful, it returns the response from the server. If there is an error,
 * it returns false.
 */
// REMEMBER ME
// Use useEffect to retrieve saved login information
useEffect(() => {
  const savedEmail = localStorage.getItem('email');
  const savedPassword = atob(localStorage.getItem('password'));
  if (savedEmail && savedPassword) {
    setEmail(savedEmail);
    setPassword(savedPassword);
    setRememberMe(true); // Check the box if login information is present
  }
}, []);

/**
 * This function sends a POST request to the backend with the user's credentials.
 *
 * @param {Object} credentials - An object containing the user's email and password.
 * @returns {Promise<Object|boolean>} - The response from the server if the request is successful,
 *                                      or false if there is an error.
 */
const loginUser = async (credentials) => {
  try {
    axios.defaults.withCredentials = true;
    return await axios.post(`${env.REACT_APP_BACK_URL}/login`, credentials);
  } catch (err) {
    return false;
  }
};

/**
 * This function checks if the user has any generations.
 *
 * It sends a GET request to the backend with the user's credentials.
 * The backend then checks if the user has any generations and returns the result.
 * If the user has generations, the function returns true.
 * If the user does not have any generations, the function returns false.
 * If there is an error while checking, the function logs the error and returns false.
 *
 * @returns {boolean} - True if the user has generations, false otherwise.
 */
const checkUserGenerations = async () => {
  try {
    const response = await axios.get(`${env.REACT_APP_BACK_URL}/generation`, { withCredentials: true });
    console.log('user has generation', response.data.generation);
    return response.data.generation;
  } catch (err) {
    console.error('Error checking user generations:', err);
    return false;
  }
};

  /**
   * This function is used to close the alert message.
   * It sets the visibility of the alert to false and clears the message.
   */
  const handleAlertClose = async () => {
    setVisible(false);
    setMessage('');
  };

/**
 * This function handles the submission of the login form.
 *
 * It first checks if the email and password fields are not empty.
 * If they are not empty, it sends a POST request to the backend with the email and password.
 * The backend then handles the authentication and returns a success message along with the user data.
 * If the login is successful, the user data is stored in local storage and the login status is passed to the parent component.
 * The user is then navigated to the dashboard or the generator page based on whether they have generations or not.
 *
 * If the email or password fields are empty, it does not send the request and does not perform any actions.
 *
 * If the login is unsuccessful, it sets an error message and displays it to the user.
 *
 * If the "Remember Me" checkbox is checked, it stores the email and password in local storage.
 * If the "Remember Me" checkbox is not checked, it removes the email and password from local storage.
 */
const handleSubmit = async (e) => {
  if (email !== '' && password !== '') {
    const loginAnswer = await loginUser({
      email,
      password,
    });

      if (loginAnswer.data.success) {
        setMessage(<Alertbox type="success" text={loginAnswer.data.message} />);
        setVisible(true);
        localStorage.setItem('user', JSON.stringify(loginAnswer.data.user));

        if (rememberMe) {
          localStorage.setItem('email', email);
          localStorage.setItem('password', btoa(password));
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }

        await passLoginStatus(true);
        if (onLogged !== undefined) {
          onLogged();
        }

        setTimeout(async () => {
          const hasGenerations = await checkUserGenerations();
          if (hasGenerations) {
            navigate('/dashboard');
          } else {
            navigate('/generator');
          }
        }, 2000); // 2 seconds delay
      } else {
        setMessage(<Alertbox type="error" text={loginAnswer.data.message} />);
        setVisible(true);
        setTimeout(handleAlertClose, 2000);
      }
    }
  };


/**
 * This function checks if the user has accepted cookie consent.
 *
 * It retrieves the 'cookieConsent' value from local storage.
 * If the value is not 'accepted', it sets an error message and displays it to the user.
 * If the value is 'accepted', it returns true.
 *
 * @returns {boolean} - True if the user has accepted cookie consent, false otherwise.
 */
const checkCookieConsent = () => {
  const cookieConsent = localStorage.getItem('cookieConsent');
  if (cookieConsent !== 'accepted') {
    setMessage(<Alertbox type="error" text="You must accept cookies to login with a social network." />);
    setVisible(true);
    setTimeout(handleAlertClose, 2000)
    return false;
  }
  return true;
};



/**
 * This function handles the Facebook login process.
 *
 * On success, it sends a POST request to the backend with the response from Facebook.
 * The backend then handles the authentication and returns a success message along with the user data.
 * If the login is successful, the user data is stored in local storage and the login status is passed to the parent component.
 * The user is then navigated to the dashboard or the generator page based on whether they have generations or not.
 *
 * On error, it sets an error message and displays it to the user.
 *
 * On cancel, it sets a warning message indicating that the Facebook login was canceled and displays it to the user.
 */
const facebookLogin = () => {

  if (!checkCookieConsent()) return; // Vérification des cookies

  window.FB.login(function (response) {
    console.log('Facebook response', response)
    if (response.authResponse && response.authResponse !== null) {
      console.log('Facebook response', response)
      fetch(`${env.REACT_APP_BACK_URL}/auth/facebook`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(response)
      })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.success) {
          localStorage.setItem('user', JSON.stringify(res.user))
          await passLoginStatus(true)
          if (onLogged !== undefined) {
            onLogged()
          }
          setTimeout(async () => {
            const hasGenerations = await checkUserGenerations()
            if (hasGenerations) {
              navigate('/dashboard')
            } else {
              navigate('/generator')
            }
          }, 2000); // 2 seconds delay
        } else {
          setMessage(<Alertbox type="error" text={res.message} />)
          setVisible(true)
          setTimeout(handleAlertClose, 2000)
        }
      })
    } else if(response.authResponse === null && response.status === "unknown"){
      setMessage(<Alertbox type="warning" text="Login by Facebook canceled." />)
      setVisible(true)
      setTimeout(handleAlertClose, 2000)
    } else {
      setMessage(<Alertbox type="error" text="Cannot login by facebook" />)
      setVisible(true)
      setTimeout(handleAlertClose, 2000)
    }
  }, {scope: 'email'})
}

/**
 * This function handles the Google login process.
 *
 * On success, it sends a POST request to the backend with the token response from Google.
 * The backend then handles the authentication and returns a success message along with the user data.
 * If the login is successful, the user data is stored in local storage and the login status is passed to the parent component.
 * The user is then navigated to the dashboard or the generator page based on whether they have generations or not.
 *
 * On error, it sets an error message and displays it to the user.
 *
 * On non-OAuth error, it sets a warning message indicating that the Google login was canceled and displays it to the user.
 */
const googleLogin = useGoogleLogin({
  onSuccess: (tokenResponse) => {
    if (!checkCookieConsent()) return; // Vérification des cookies

    fetch(`${env.REACT_APP_BACK_URL}/auth/google`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tokenResponse),
    })
    .then((res) => res.json())
    .then(async (res) => {
      if (res.success) {
        localStorage.setItem('user', JSON.stringify(res.user));
        await passLoginStatus(true);
        if (onLogged !== undefined) {
          onLogged();
        }
        setTimeout(async () => {
          const hasGenerations = await checkUserGenerations();
          if (hasGenerations) {
            navigate('/dashboard');
          } else {
            navigate('/generator');
          }
        }, 2000); // 2 seconds delay
      } else {
        setMessage(<Alertbox type="error" text={res.message} />);
        setVisible(true);
        setTimeout(handleAlertClose, 2000);
      }
    });
  },
  onError: () => {
    setMessage(<Alertbox type="error" text="Cannot login by google." />);
    setVisible(true);
    setTimeout(handleAlertClose, 2000);
  },
  onNonOAuthError: () => {
    setMessage(<Alertbox type="warning" text="Login by Google canceled." />);
    setVisible(true);
    setTimeout(handleAlertClose, 2000);
  },
});

  /**
   * This function navigates the user to the registration page.
   * It is triggered when the user clicks on the "Sign up now" link.
   */
  const handleRegister = async () => {
    navigate('/register');
  };

  //RENDU
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryBorder:`linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryHover: `linear-gradient(185deg, #CB1000, #F1A300)`,
              colorPrimaryActive: `linear-gradient(185deg, #CB1000, #F1A300)`,
              lineWidth: 1,
            },
            Checkbox: {
              colorPrimary: `#F1A300`,
              colorPrimaryHover: `#F1A300`,
              colorPrimaryActive: `#CB1000`,
            },
            Input:{
              height:50,
            }
          },
        }}
      >
      <div className="steps-home-login">
        <div className="loginMainContainer">
        <div className="alert-cont">
            <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
            >
              {visible && message}
            </Space>
          </div>
          <div className="loginFormContainer">
            <div className="loginFormOnboardingTitle">
              <div className="loginFormTitle">
                <p>
                  Welcome to <span>Weebseat</span>
                </p>
              </div>
              {/*
              <div className="loginFormPara">
                <p>Securing the places where people live and thrive.</p>
              </div>
              */}
            </div>
            <div className="LoginForm">
              <div className="loginTitle">
                <h2>LOGIN</h2>
              </div>
              <Form 
                name="normal_login" 
                style={{width: "100%"}}
                initialValues={{ remember: true }}
              >
              <Form.Item className="login-form">
                <Button
                  block
                  className="social-login-button facebook-button"
                  icon={<FacebookFilled />}
                  style={{ marginBottom: 10 }}
                  onClick={facebookLogin}
                  >
                  Facebook
                </Button>
                <Button
                  block
                  className="social-login-button google-button"
                  icon={<GoogleCircleFilled />}
                  style={{ marginBottom: 10 }}
                  onClick={() => googleLogin()}
                  >
                  Google
                </Button>
                {/**
                 
                <Button
                  block
                  className="social-login-button apple-button"
                  icon={<AppleOutlined />}
                  style={{ marginBottom: 10 }}
                  onClick={() => appleLogin()}
                  >
                  Apple
                </Button>
                 */}
                <AppleSignin
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                  /** Client ID - eg: 'com.example.com' */
                  clientId: 'com.weebseat.login.service',
                  /** Requested scopes, seperated by spaces - eg: 'email name' */
                  scope: 'email name',
                  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                  redirectURI: `${env.REACT_APP_FRONT_URL}/login`,
                  /** State string that is returned with the apple response */
                  state: 'state',
                  /** Nonce */
                  nonce: 'nonce',
                  /** Uses popup auth instead of redirection */
                  usePopup: true,
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={async (response) => {
                  if (!checkCookieConsent()) return;
                  try {
                    const res = await fetch(`${env.REACT_APP_BACK_URL}/auth/apple`, {
                      method: 'POST',
                      credentials: 'include',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(response),
                    });
              
                    const data = await res.json();
              
                    if (data.success) {
                      localStorage.setItem('user', JSON.stringify(data.user));
                      await passLoginStatus(true);
                      if (onLogged) {
                        onLogged();
                      }
                      setTimeout(async () => {
                        const hasGenerations = await checkUserGenerations();
                        if (hasGenerations) {
                          navigate('/dashboard');
                        } else {
                          navigate('/generator');
                        }
                      }, 2000); // 2 seconds delay
                    } else {
                      setMessage(<Alertbox type="error" text={data.message} />);
                      setVisible(true);
                      setTimeout(handleAlertClose, 2000);
                    }
                  } catch (error) {
                    console.error('Error sending data to backend:', error);
                    setMessage(<Alertbox type="error" text="An error occurred while logging in with Apple." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }
                }}
                onError={(error) => {
                  console.error(error)
                  if(error['error'] === 'popup_closed_by_user'){
                    setMessage(<Alertbox type="warning" text="Login by Apple canceled." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }else{
                    setMessage(<Alertbox type="error" text="Apple Login failed." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }
                }}
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
                /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                render={(props) => <Button 
                {...props}
                icon={<AppleOutlined />}
                style={{ marginBottom: 10 }}
                className="social-login-button apple-button"
                >
                  Apple
                </Button>}
              />
              </Form.Item>

                <Divider>Or</Divider>

                <div className="loginInputContainer">
                  <label>
                    Email:
                  </label>
                  <Form.Item
                    name="email"
                    rules={rememberMe ? [] : [{ required: true, message: 'Please input your Email!' }]}
                    onChange={(e) => setEmail(e.target.value)}
                  >
                    <div className="loginInputField">
                      <Input  className='login-input' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" variant={false} value={email}/>
                    </div>
                  </Form.Item>
                </div>
                <div className="loginInputContainer">
                <label>Password:</label>
                <Form.Item
                  name="password"
                  rules={rememberMe ? [] : [{ required: true, message: 'Please input your password!' }]}
                  onChange={(e) => setPassword(e.target.value)}
                >
                  <div className="loginInputField2">
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Password"
                      className="togglePassInput pass-input"
                      variant={false}
                      value={password}
                      
                    />
                    <span className="togglePassIcon">
                      {/*<img src="./assets/login/eye-close.svg" alt="" />*/}
                    </span>
                  </div>
                </Form.Item>
                </div>
                <div className="additionalAuth">
    <div className='rem-cont'>
      <Checkbox id="loginCheckbox" className="custom-checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
        Remember me
      </Checkbox>
    </div>
    <a href="/pass-recovery" className="additional-auth-txt">
      Having trouble?
    </a>
                </div>
                <Form.Item>
                  <Button className="login" type="primary" htmlType="submit" onClick={handleSubmit}>
      Login
                  </Button>
                  <div className='register-link-cont'>
                    <span>Don't have an account? </span>&nbsp;<span className="register-link" onClick={handleRegister}>Sign up now</span>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </ConfigProvider>
    </>
  );
};

export default Login;
